<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        class="text-subtitle-1 text-md-subtitle-1 py-6"
        width="100%"
        elevation="5"
        @click="isShow = !isShow"
      >
        출금신청
      </v-btn>
      <v-bottom-sheet
        v-model="isShow"
        inset
      >
        <v-card
          height="250px"
          elevation="5"
        >
          <v-card-title>
            <h3>
              출금신청
            </h3>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="amount"
              label="금액"
              type="number"
              hide-spin-buttons
              class="text-body-1"
              append-icon="mdi-currency-krw"
            />
          </v-card-text>
          <v-card-actions class="flex-wrap">
            <v-btn
              width="100%"
              elevation="5"
              class="text-subtitle-1 text-md-subtitle-1 py-6"
              @click="registerWithdraw"
            >
              완료
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'PointWithDraw',
    data () {
      return {
        isShow: false,
        amount: null,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    watch: {
      isShow () {
        if (!this.isShow) {
          this.amount = null
        }
      },
    },
    methods: {
      close () {
        this.isShow = false
        this.amount = null
      },
      registerWithdraw () {
        const url = '/user/withdraw'
        const data = {
          amount: this.amount,
        }
        this.$axios.post(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('포인트 출금 신청이 완료되었습니다.')
            this.$emit('refresh')
            this.isShow = false
            this.amount = null
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
    },
  }
</script>

<style scoped>

</style>
