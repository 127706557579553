<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-2">
        mdi-account-multiple-plus
      </v-icon>
      <h5>
        {{ alias.id }} 상세정보
      </h5>
    </v-card-title>
    <v-card-text class="pb-1">
      <v-text-field
        v-model="thisAlias.username"
        label="Name"
        hide-details
        outlined
        dense
        class="mb-3"
      />
      <v-text-field
        v-model="thisAlias.mobile"
        label="Mobile"
        hide-details
        outlined
        dense
        class="mb-3"
      />
      <v-text-field
        v-model="thisAlias.userAddress"
        label="Address"
        hide-details
        outlined
        dense
        class="mb-3"
        readonly
        @focus="addressSearch"
      />
      <v-text-field
        v-model="thisAlias.userAddressDetail"
        label="Address Detail"
        hide-details
        outlined
        dense
        class="mb-3"
      />
    </v-card-text>
    <v-card-actions class="d-block text-right">
      <v-btn width="100%" class="mx-0 mb-2" @click="saveAlias">
        저장하기
      </v-btn>
      <v-btn width="100%" class="mx-0" @click="close">
        창닫기
      </v-btn>
    </v-card-actions>
    <DaumAddress
      ref="aliasAddress"
      :show-address="isShowAddress"
      :style="{ zIndex: 1000 }"
      @close="isShowAddress = false"
      @update="updateAddress"
    />
  </v-card>
</template>

<script>
  import DaumAddress from '@/components/core/DaumAddress'
  export default {
    name: 'AliasDetails',
    components: { DaumAddress },
    props: {
      alias: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        thisAlias: this.alias,
        isShowAddress: false,
      }
    },
    methods: {
      close () {
        this.$emit('close-dialog')
      },
      updateAddress (address) {
        this.thisAlias.userAddress = address
      },
      addressSearch () {
        this.$refs.aliasAddress.addressSearch()
        this.isShowAddress = true
      },
      async saveAlias () {
        if (!this.thisAlias.username) {
          alert('이름을 입력해주세요.')
          return
        }
        if (!this.thisAlias.mobile) {
          alert('휴대폰 번호를 입력해주세요.')
          return
        }
        const mobilePattern = /^[0-9]+$/
        if (!mobilePattern.test(this.thisAlias.mobile)) {
          alert('휴대폰 번호는 숫자만 입력해주세요.')
          return
        }
        if (!this.thisAlias.userAddress) {
          alert('주소를 입력해주세요.')
          return
        }
        if (!this.thisAlias.userAddressDetail) {
          alert('상세 주소를 입력해주세요.')
          return
        }
        const url = '/user/alias'
        await this.$axios.patch(url, this.thisAlias).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('추가 사용자를 수정하였습니다.')
            this.close()
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
    },
  }
</script>

<style scoped>

</style>
