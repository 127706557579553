<template>
  <v-expansion-panels class="mt-5">
    <v-expansion-panel>
      <v-expansion-panel-header class="align-center">
        <div class="d-flex align-center text-h6">
          <v-icon class="mr-2">
            mdi-account
          </v-icon>
          사용자 정보
          <v-btn
            v-if="!$store.state.user.user.admin"
            small
            class="ml-3"
            color="error"
            @click="disableUser"
          >
            회원탈퇴
          </v-btn>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="user.id"
              label="ID"
              disabled
              dense
              filled
              outlined
            />
            <v-text-field
              v-model="user.username"
              label="Username"
              disabled
              dense
              filled
              outlined
            />
            <v-text-field
              v-model="user.password"
              type="password"
              label="Password"
              dense
              filled
              outlined
            />
            <v-text-field
              v-model="user.passwordConfirm"
              type="password"
              label="Password-Confirm"
              dense
              filled
              outlined
            />
            <v-text-field
              v-model="user.userAddress"
              label="Address"
              dense
              filled
              outlined
              @focusin="addressSearch"
            />
            <v-text-field
              v-model="user.userAddressDetail"
              label="Address Detail"
              dense
              filled
              outlined
            />
            <v-text-field
              v-model="user.email"
              label="E-mail"
              dense
              filled
              outlined
            />
            <v-text-field
              v-model="user.mobile"
              label="Mobile"
              dense
              filled
              outlined
            />
            <v-text-field
              v-model="user.userAccount"
              label="Account Info"
              dense
              filled
              outlined
            />
            <v-select
              v-model="hideSoldOut"
              :items="hideItems"
              dense
              label="품절상품 숨김여부"
              filled
              outlined
              @change="updateHideSoldOut"
            />
            <v-btn
              class="text-subtitle-1 text-md-subtitle-1 py-6"
              width="100%"
              elevation="5"
              @click="saveUser"
            >
              정보저장
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <DaumAddress
      ref="address"
      :show-address="isShowAddress"
      :style="{ zIndex: 1000 }"
      @close="isShowAddress = false"
      @update="updateAddress"
    />
  </v-expansion-panels>
</template>

<script>
  import DaumAddress from '../../../components/core/DaumAddress'
  export default {
    name: 'UserInfo',
    components: { DaumAddress },
    data () {
      return {
        password: null,
        isShowAddress: false,
        hideSoldOut: this.$store.state.user.user.hideSoldOut,
        hideItems: [
          {
            text: '노출',
            value: false,
          },
          {
            text: '숨김',
            value: true,
          },
        ],
      }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
    },
    methods: {
      async updateHideSoldOut () {
        const url = '/user/hideSoldOut'
        await this.$axios.patch(url, { hideSoldOut: this.hideSoldOut }).then(res => {
          if (!res.data.status === 'SUCCESS') {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      addressSearch () {
        this.$refs.address.addressSearch()
        this.isShowAddress = true
      },
      async saveUser () {
        const url = '/user/update'
        if (this.validateUserInfo()) {
          await this.$axios.patch(url, this.user).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('사용자 정보가 정상적으로 수정되었습니다.')
              this.$emit('refresh')
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      // eslint-disable-next-line max-statements
      validateUserInfo () {
        if (!this.user.email) {
          alert('이메일을 입력해주세요.')
          return false
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)) {
          alert('이메일 형식에 맞지 않습니다.')
          return false
        }
        if (this.user.passwordConfirm) {
          if (this.user.password !== this.user.passwordConfirm) {
            alert('패스워드가 서로 일치하지 않습니다.')
            this.user.passwordConfirm = null
            return false
          }
          if (!/^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,20}$/.test(this.user.password)) {
            alert('패스워드는 영문, 숫자, 특수문자 중 2가지 이상 조합하여 8~20자로 입력해주세요.')
            return false
          }
        } else {
          this.user.password = null
        }
        if (!this.user.userAddress) {
          alert('주소를 입력해주세요.')
          return false
        }
        if (!this.user.userAddressDetail) {
          alert('상세주소를 입력해주세요.')
          return false
        }
        if (!this.user.mobile) {
          alert('전화번호를 입력해주세요.')
          return false
        }
        if (!this.user.userAccount) {
          alert('계좌정보를 입력해주세요.')
          return false
        }
        return true
      },
      updateAddress (address) {
        this.user.userAddress = address
      },
      async disableUser () {
        if (confirm('더이상 사이트 내 활동이 불가하며, 로그인이 불가합니다.\r\n탈퇴하시겠습니까?')) {
          const url = '/user/disable'
          await this.$axios.patch(url).then(res => {
            if (res.data.status === 'SUCCESS') {
              this.$store.dispatch('user/logout')
            } else {
              alert(res.data.message || '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
    },
  }
</script>

<style scoped>

</style>
