<template>
  <v-expansion-panels class="mt-5">
    <v-expansion-panel>
      <v-expansion-panel-header class="align-center">
        <div class="d-flex align-center text-h6">
          <v-icon class="mr-2">
            mdi-format-list-bulleted
          </v-icon>
          포인트 내역
          <v-badge
            v-if="pagination && pagination.totalDataSize > 0"
            :content="pagination.totalDataSize"
            color="red"
          />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mb-3">
          <v-col cols="12" md="3">
            <v-select
              v-model="pointType"
              dense
              hide-details
              :items="pointTypes"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-menu>
              <template #activator="{ attrs, on }">
                <v-text-field
                  v-model="startDate"
                  label="Start"
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar-check"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                locale="ko"
                type="date"
                @change="saveStartDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="6" md="3">
            <v-menu>
              <template #activator="{ attrs, on }">
                <v-text-field
                  v-model="endDate"
                  label="End"
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar-check"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                locale="ko"
                type="date"
                @change="saveEndDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="keyword"
              dense
              hide-details
              label="검색어"
              append-icon="mdi-magnify"
              @click:append="getPagination(1)"
              @keyup="enterSearch"
            />
          </v-col>
        </v-row>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="(col, i) in cols"
                  :id="`point-cols-${i}`"
                  :key="i"
                  class="text-subtitle-1 text-center"
                >
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!pagination || pagination.list.length === 0">
              <tr>
                <td :colspan="cols.length" class="text-body-1">
                  <p class="text-center mt-5 mb-2">
                    포인트 변경 내역이 없습니다.
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(history, i) in pagination.list" :key="i">
                <td class="text-center" @click="goToArticle(history)">
                  <span class="text-body-1 text-truncate text-caption text-md-body-1">
                    {{ history.type }}
                  </span>
                </td>
                <td class="text-center" @click="goToArticle(history)">
                  <span class="text-body-1 text-truncate text-caption text-md-body-1">
                    {{ history.amount.toLocaleString() }}
                  </span>
                </td>
                <td :style="{ minWidth: '90px' }" class="px-0 text-center" @click="goToArticle(history)">
                  <span class="text-body-1 text-caption text-md-body-1">
                    {{ history.content ? history.content : '-' }}
                  </span>
                </td>
                <td :style="{ minWidth: '90px' }" class="px-0 text-center" @click="goToArticle(history)">
                  <span class="text-body-1 text-caption text-md-body-1">
                    {{ history.article && history.article.company ? history.article.company.companyName : '-' }}
                  </span>
                </td>
                <td class="text-center" @click="goToArticle(history)">
                  <span class="text-body-1 text-truncate text-caption text-md-body-1">
                    {{ history.registerDate | moment('YYYY.MM.DD HH:mm:ss') }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-sheet class="px-3 mt-3">
          <v-row>
            <v-col cols="12" md="8" offset-md="2">
              <LivePagination
                :pagination="pagination"
                :show-num="7"
                :query="$route.query"
                @pagination="getPagination"
                @last="getLastPage"
                @first="getFirstPage"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import LivePagination from '@/components/core/LivePagination'
  export default {
    name: 'PointHistory',
    components: { LivePagination },
    data () {
      return {
        cols: ['타입', '금액', '내용', '업체', '날짜'],
        pagination: {
          list: [
            {
              type: '활동',
              amount: -10000,
              content: '차감',
              date: '2022-03-16 10:10:55',
            },
          ],
          pageNum: 1,
        },
        pageDataSize: 10,
        pointType: '',
        pointTypes: [
          {
            text: '전체',
            value: '',
          },
          {
            text: '활동',
            value: 'activity',
          },
          {
            text: '적립',
            value: 'fee',
          },
          {
            text: '추천',
            value: 'recommend',
          },
          {
            text: '출금',
            value: 'withdraw',
          },
        ],
        sdt: '',
        edt: '',
        keyword: '',
      }
    },
    computed: {
      startDate: {
        get () {
          if (this.sdt) {
            const d = new Date()
            const year = parseInt(this.sdt.split('-')[0])
            const month = parseInt(this.sdt.split('-')[1])
            const day = parseInt(this.sdt.split('-')[2])
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            return this.$moment(d).format('YYYY-MM-DD')
          } else {
            return null
          }
        },
        set (value) {
          this.sdt = value
        },
      },
      endDate: {
        get () {
          if (this.edt) {
            const d = new Date()
            const year = parseInt(this.edt.split('-')[0])
            const month = parseInt(this.edt.split('-')[1])
            const day = parseInt(this.edt.split('-')[2])
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            return this.$moment(d).format('YYYY-MM-DD')
          } else {
            return null
          }
        },
        set (value) {
          this.edt = value
        },
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      saveStartDate (date) {
        this.sdt = date
      },
      saveEndDate (date) {
        this.edt = date
      },
      goToArticle (item) {
        const article = item.article
        if (article && article.idx) {
          const url = this.$router.resolve({ path: `/article/${article.idx}` })
          window.open(url.href)
        }
      },
      getPagination (pageNum) {
        const url = `/user/history/point/${pageNum || this.pagination.pageNum}`
        const params = {
          pointType: this.pointType,
          sdt: this.sdt ? this.sdt : null,
          edt: this.edt ? this.edt : null,
          keyword: this.keyword ? this.keyword : null,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getLastPage () {
        const url = `/user/history/point/${this.pagination.maxPageNum}`
        const params = {
          pointType: this.pointType,
          sdt: this.sdt ? this.sdt : null,
          edt: this.edt ? this.edt : null,
          keyword: this.keyword ? this.keyword : null,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getFirstPage () {
        const url = '/user/history/point/1'
        const params = {
          pointType: this.pointType,
          sdt: this.sdt ? this.sdt : null,
          edt: this.edt ? this.edt : null,
          keyword: this.keyword ? this.keyword : null,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.getPagination(1)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
