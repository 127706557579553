import { render, staticRenderFns } from "./PointCards.vue?vue&type=template&id=49ce9039&scoped=true&"
import script from "./PointCards.vue?vue&type=script&lang=js&"
export * from "./PointCards.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ce9039",
  null
  
)

export default component.exports