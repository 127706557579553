<template>
  <v-col cols="12" md="4">
    <v-card>
      <v-card-title class="justify-center">
        {{ title ? title : '-' }}
      </v-card-title>
      <v-card-text class="text-center pb-10 pt-5">
        <h1>
          {{ points ? points.toLocaleString() : 0 }}
        </h1>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
  export default {
    name: 'Points',
    props: {
      title: String,
      points: Number,
    },
  }
</script>

<style scoped>

</style>
