<template>
  <v-expansion-panels class="mt-5">
    <v-expansion-panel>
      <v-expansion-panel-header class="align-center">
        <div class="d-flex align-center text-h6">
          <v-icon class="mr-2">
            mdi-format-list-bulleted
          </v-icon>
          게시글 목록
          <v-badge
            v-if="pagination && pagination.totalDataSize > 0"
            :content="pagination.totalDataSize"
            color="red"
          />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table style="overflow-x: auto">
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="(col, i) in cols"
                  :id="`article-cols-${i}`"
                  :key="i"
                  class="text-subtitle-1 text-truncate"
                >
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!pagination || pagination.list.length === 0">
              <tr>
                <td :colspan="cols.length" class="text-body-1">
                  <p class="text-center mt-5 mb-2">
                    작성한 게시글이 없습니다.
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(article, i) in pagination.list" :key="i" @click="alert(1)">
                <td @click="clickArticle(article)">
                  <span class="text-body-1 text-truncate">
                    {{ article.idx }}
                  </span>
                </td>
                <td class="text-body-1 text-truncate" @click="clickArticle(article)">
                  {{ article.company ? article.company.companyName : '-' }}
                </td>
                <td @click="clickArticle(article)">
                  <span class="text-body-1 text-truncate">
                    <template v-if="article.productRegistration && article.productRegistration.product && article.productRegistration.product.month">
                      {{ article.type === '배송완료' ? '구매확정' : article.type }}
                    </template>
                    <template v-else>
                      {{ article.type }}
                    </template>
                  </span>
                </td>
                <td @click="clickArticle(article)">
                  <span class="text-body-1 text-truncate">
                    {{ article.status === 'pending' ? '대기' : article.status === 'confirm' ? '승인' : article.status === 'reject' ? '반려' : '-' }}
                  </span>
                </td>
                <td @click="clickArticle(article)">
                  <v-dialog
                    v-model="article.showDialog"
                    width="800px"
                    max-width="100%"
                    :fullscreen="mobile"
                    :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                  >
                    <template #activator="{ attrs, on }">
                      <span
                        :id="'article-' + article.idx"
                        class="text-body-1 text-truncate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ article.title }}
                      </span>
                    </template>
                    <v-card>
                      <v-card-text>
                        <BoardViewContent
                          :article="article"
                          :popup="true"
                          :product="article.productRegistration && article.productRegistration.product ? article.productRegistration.product : {}"
                          :options="article.productRegistration && article.productRegistration.options ? article.productRegistration.options : {}"
                          @refresh="changeValue"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-btn large width="100%" @click="article.showDialog = false">
                          창 닫기
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>
                <td @click="clickArticle(article)">
                  <span class="text-body-1 text-truncate">
                    <span v-if="new Date().getTime() - article.registerDate < 1000 * 60 * 60 * 7">
                      {{ article.registerDate | moment('from', 'now') }}
                    </span>
                    <span v-else>
                      {{ article.registerDate | moment('YYYY.MM.DD. HH:mm:ss') }}
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-sheet class="px-3 mt-3">
          <v-row>
            <v-col cols="12" md="8" offset-md="2">
              <LivePagination
                :pagination="pagination"
                :show-num="7"
                :query="$route.query"
                @pagination="getPagination"
                @last="getLastPage"
                @first="getFirstPage"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import $ from 'jquery'
  import LivePagination from '@/components/core/LivePagination'
  import BoardViewContent from '@/views/board/view/Content'
  export default {
    name: 'ArticleHistory',
    components: { BoardViewContent, LivePagination },
    data () {
      return {
        cols: ['글번호', '업체', '게시판', '상태', '제목', '날짜'],
        pagination: {
          list: [],
          pageNum: 1,
        },
        pageDataSize: 10,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      getPagination (pageNum) {
        const url = '/article/articles'
        const params = {
          pageNum: pageNum || this.pagination.pageNum,
          pageDataSize: this.pageDataSize,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getLastPage () {
        const url = '/article/articles'
        const params = {
          pageNum: this.pagination.maxPageNum,
          pageDataSize: this.pageDataSize,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      clickArticle (article) {
        $('#article-' + article.idx).click()
      },
      getFirstPage () {
        const url = '/article/articles/'
        const params = {
          pageNum: '1',
          pageDataSize: this.pageDataSize,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
