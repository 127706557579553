<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <MyPageHeader />
      <PointCards ref="pointCards" />
      <PointWithDraw @refresh="refresh" />
      <PointHistory ref="pointHistory" />
      <ArticleHistory />
      <ProductRegistrationHistory />
      <UserAlias />
      <UserInfo @refresh="refresh" />
    </v-container>
  </v-lazy>
</template>

<script>
  import MyPageHeader from '@/views/my-page/Header'
  import PointCards from '@/views/my-page/points/PointCards'
  import PointHistory from '@/views/my-page/points/PointHistory'
  import PointWithDraw from '@/views/my-page/points/PointWithDraw'
  import ArticleHistory from '@/views/my-page/articles/ArticleHistory'
  import UserInfo from '@/views/my-page/user/UserInfo'
  import UserAlias from '@/views/my-page/alias/UserAlias'
  import ProductRegistrationHistory from '@/views/my-page/registration/ProductRegistrationHistory'
  export default {
    name: 'MyPage',
    components: { ProductRegistrationHistory, UserAlias, UserInfo, ArticleHistory, PointWithDraw, PointHistory, PointCards, MyPageHeader },
    data: () => ({
      items: [{
        value: 'en',
        text: 'English (United States)',
      }],
      language: 'ko',
    }),
    methods: {
      refresh () {
        this.$refs.pointHistory.getPagination()
        this.$axios.get('/user/info', {
          headers: {
            'jwt-auth-token': sessionStorage.getItem('jwt-auth-token') ? sessionStorage.getItem('jwt-auth-token') : '',
          },
        })
          .then(res => {
            if (res.data) {
              this.$store.dispatch('user/refresh', res.data)
            }
          })
      },
    },
  }
</script>
