<template>
  <v-row>
    <Points title="활동 포인트" :points="user.activityPoints" />
    <Points title="적립 포인트" :points="user.feePoints" />
    <Points title="추천인 포인트" :points="user.recommendPoints" />
  </v-row>
</template>

<script>
  import Points from '@/views/my-page/points/Points'
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'PointCards',
    components: { Points },
    data () {
      return {
      }
    },
    computed: {
      ...mapState('user', [
        'user',
      ]),
    },
  }
</script>

<style scoped>

</style>
