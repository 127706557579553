<template>
  <v-expansion-panels class="mt-5">
    <v-expansion-panel>
      <v-expansion-panel-header class="align-center">
        <div class="d-flex align-center text-h6">
          <v-icon class="mr-2">
            mdi-account-multiple-plus
          </v-icon>
          추가 사용자 관리
          <v-dialog
            v-model="isShowRegister"
            width="400"
            persistent
            :fullscreen="mobile"
            :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                class="ml-3 black--text"
                color="grey"
                small
                v-bind="attrs"
                v-on="on"
              >
                사용자 추가
              </v-btn>
            </template>
            <v-card class="d-flex align-start flex-wrap">
              <v-card-title class="mb-auto pt-10 pt-md-3" :style="{ width: '100%' }">
                <v-icon class="mr-1">
                  mdi-plus
                </v-icon>
                사용자 추가
              </v-card-title>
              <v-card-text class="mb-auto d-flex align-start">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="newUser.id" label="ID" />
                    <v-text-field v-model="newUser.name" label="Name" />
                    <v-text-field v-model="newUser.mobile" label="Mobile" />
                    <v-text-field v-model="newUser.address" label="Address" @focus="addressSearch" />
                    <v-text-field v-model="newUser.userAddressDetail" label="Address Detail" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="flex-wrap mt-auto" :style="{ width: '100%' }">
                <v-btn
                  width="100%"
                  large
                  class="mx-0 mb-2"
                  @click="registerAlias"
                >
                  완료
                </v-btn>
                <v-btn
                  width="100%"
                  large
                  class="mx-0"
                  @click="close"
                >
                  창 닫기
                </v-btn>
              </v-card-actions>
            </v-card>
            <DaumAddress
              ref="address"
              :show-address="isShowAddress"
              :style="{ zIndex: 1000 }"
              @close="isShowAddress = false"
              @update="updateAddress"
            />
          </v-dialog>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table style="overflow-x: auto">
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="(col, i) in cols"
                  :id="`alias-cols-${i}`"
                  :key="i"
                  class="text-subtitle-1 text-truncate text-center"
                >
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!aliases || aliases.length === 0 ">
              <tr>
                <td :colspan="cols.length" class="text-body-1">
                  <p class="text-center mt-2 mb-2">
                    등록된 추가 사용자가 존재하지 않습니다.
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="alias in aliases" :key="`alias-${alias.idx}`">
                <td class="text-center">
                  <v-btn
                    icon
                    @click="deleteAlias(alias)"
                  >
                    <v-icon>
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </td>
                <td class="text-center text-truncate" @click="openAliasDetail(alias)">
                  {{ alias.id }}
                </td>
                <td class="text-center text-truncate" @click="openAliasDetail(alias)">
                  {{ alias.username }}
                </td>
                <td class="text-center text-truncate" @click="openAliasDetail(alias)">
                  {{ alias.mobile || '-' }}
                </td>
                <td class="text-center text-truncate" @click="openAliasDetail(alias)">
                  {{ alias.userAddress ? alias.userAddress + ' ' + (alias.userAddressDetail || '') : '주소 정보 없음' }}
                </td>
                <td class="text-center" @click="openAliasDetail(alias)">
                  {{ alias.joinDate ? $moment(alias.joinDate).format('YYYY.MM.DD HH:mm:ss') : '-' }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-dialog
          v-model="showAliasDetails"
          persistent
          width="400"
          max-width="100%"
        >
          <AliasDetails v-if="showAliasDetails" :alias="selectedAlias" @close-dialog="closeAliasDetail" />
        </v-dialog>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import DaumAddress from '@/components/core/DaumAddress'
  import AliasDetails from '@/components/alias/AliasDetails'
  export default {
    name: 'UserAlias',
    components: { AliasDetails, DaumAddress },
    data () {
      return {
        cols: ['', '아이디', '이름', '전화번호', '주소', '등록일'],
        aliases: [],
        newUser: {
          id: null,
          name: null,
          mobile: null,
          address: null,
          userAddressDetail: null,
        },
        showAliasDetails: false,
        selectedAlias: null,
        isShowAddress: false,
        isShowRegister: false,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    beforeMount () {
      this.getAlias()
    },
    methods: {
      async getAlias () {
        const url = '/user/alias'
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.aliases = res.data
          }
        })
      },
      closeAliasDetail () {
        this.selectedAlias = null
        this.showAliasDetails = false
        this.getAlias()
      },
      openAliasDetail (alias) {
        this.selectedAlias = alias
        this.showAliasDetails = true
      },
      addressSearch () {
        this.$refs.address.addressSearch()
        this.isShowAddress = true
      },
      closeAddressSearch () {
        this.isShowAddress = false
      },
      updateAddress (address) {
        this.newUser.address = address
      },
      initNewUser () {
        this.newUser = {
          id: null,
          name: null,
          mobile: null,
          address: null,
          userAddressDetail: null,
        }
      },
      close () {
        this.isShowRegister = false
        this.initNewUser()
      },
      async registerAlias () {
        const url = '/user/alias'
        const data = {
          id: this.newUser.id,
          username: this.newUser.name,
          mobile: this.newUser.mobile,
          userAddress: this.newUser.address,
          userAddressDetail: this.newUser.userAddressDetail,
        }
        const mobilePattern = /^[0-9]+$/
        if (!mobilePattern.test(this.newUser.mobile)) {
          alert('휴대폰 번호는 숫자만 입력해주세요.')
          return
        }
        await this.$axios.post(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('추가 사용자 등록이 완료되었습니다.')
            this.getAlias()
            this.close()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      async deleteAlias (alias) {
        if (confirm('추가 사용자를 삭제하시겠습니까?')) {
          const url = '/user/alias'
          await this.$axios.delete(url, { data: { idx: alias.idx } }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('추가 사용자를 삭제처리 하였습니다.')
              this.getAlias()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
    },
  }
</script>

<style scoped>

</style>
