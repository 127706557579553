<template>
  <v-expansion-panels v-if="pagination && pagination.list.length > 0" class="mt-5">
    <v-expansion-panel>
      <v-expansion-panel-header class="align-center">
        <div class="d-flex align-center text-h6">
          <v-icon class="mr-2">
            mdi-format-list-bulleted
          </v-icon>
          신규상품 신청내역
          <v-badge
            v-if="pagination && pagination.totalDataSize > 0"
            :content="pagination.totalDataSize"
            color="red"
          />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table style="overflow-x: auto">
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="(col, i) in cols"
                  :id="`registration-cols-${i}`"
                  :key="i"
                  class="text-subtitle-1 text-truncate text-center"
                >
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(registration, i) in pagination.list" :key="i">
                <td class="text-center text-truncate text-body-1">
                  {{ registration.product ? registration.product.productName : '정보 없음' }}
                </td>
                <td class="text-center text-truncate text-body-1">
                  {{ registration.product.company ? registration.product.company.companyName : '정보 없음' }}
                </td>
                <td class="text-center text-truncate text-body-1">
                  {{ registration.purchaseDate ? $parseDate(registration.purchaseDate) : '' }}
                </td>
                <td class="text-center text-truncate text-body-1">
                  {{ registration.registerDate ? $parseDateSecond(registration.registerDate) : '' }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-sheet class="px-3 mt-3">
          <v-row>
            <v-col cols="12" md="8" offset-md="2">
              <LivePagination
                :pagination="pagination"
                :show-num="7"
                :query="$route.query"
                @pagination="getPagination"
                @last="getLastPage"
                @first="getFirstPage"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import LivePagination from '@/components/core/LivePagination'
  export default {
    name: 'ProductRegistrationHistory',
    components: { LivePagination },
    data () {
      return {
        cols: ['상품명', '등록업체', '구매예정', '신청일'],
        pagination: {
          list: [],
          pageNum: 1,
        },
        pageDataSize: 10,
      }
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      async getPagination (payload) {
        const url = '/product/registration/registrations'
        const params = {
          pageNum: payload || this.pagination.pageNum,
          pageDataSize: this.pageDataSize,
        }
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getLastPage () {
        const url = '/product/registration/registrations'
        const params = {
          pageNum: this.pagination.maxPageNum,
          pageDataSize: this.pageDataSize,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getFirstPage () {
        const url = '/product/registration/registrations'
        const params = {
          pageNum: '1',
          pageDataSize: this.pageDataSize,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
    },
  }
</script>

<style scoped>

</style>
